"use client";

import { datadogRum } from "@datadog/browser-rum";

if (process.env.NEXT_PUBLIC_ENV === "prod") {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: process.env.NEXT_PUBLIC_SITE,
    service: process.env.NEXT_PUBLIC_SERVICE,
    env: "production",
    version: `${process.env.NEXT_RELEASE_VERSION}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    startSessionReplayRecordingManually: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    // allowedTracingUrls: [process.env.NEXT_PUBLIC_API_BASE_URL]
  });

  datadogRum.startSessionReplayRecording();
}

export default function DatadogInit() {
  // doc: https://docs.datadoghq.com/real_user_monitoring/guide/monitor-your-nextjs-app-with-rum/?tab=npm
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
