import { DOMAINS } from "@/sites/domains";
import { fastQRGenerator } from "@/sites/domains/fastqrgenerator.com";
import { onlineQrCode } from "@/sites/domains/onlineqrcode.com";
import { QRCreator } from "@/sites/domains/qrcreator.com";
import { qrNow } from "@/sites/domains/qrnow.com";

export const getGTMId = (siteDomain: string) => {
  switch (siteDomain) {
    case DOMAINS[QRCreator.domainName]:
      return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_QRCREATOR;
    case DOMAINS[fastQRGenerator.domainName]:
      return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_FASTQRGENERATOR;
    case DOMAINS[onlineQrCode.domainName]:
      return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_ONLINEQRCODE;
    case DOMAINS[qrNow.domainName]:
      return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_QRNOW;
    default:
      return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_QRCREATOR;
  }
};
